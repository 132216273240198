import api from '@/api/positions'
import {notify} from "@/helpers/notifyHelper"

export default {
	name: 'AdminShow',
	data () {
		return {
			valid: false,
			postData: null,
			terms: [
			'Part leave',
			'Full time',
			'Flex time',
			'Salary',
			'Pay slip'
		]
		}
	},
	created() {
		if (this.id) {
			api.getById(this, this.id)
				.then(response => {
					if (response.data.length) {
						this.postData = response.data[0]
					}
				})
				.catch(err => {
					notify(self, 'error', err)
				})
		}
	},
	methods: {
		update () {
			if (this.$refs.form.validate()) {
				var regExpression = /^[a-zA-Z0-9-]*$/
				if (!regExpression.test(this.postData.urlName)) {
					notify(this, 'error', 'Link may be string and - ')
					return
				}

				api.update(this, this.postData.id, this.postData)
					.then(() => {
						this.$router.push({name: 'Positions'})
					})
			}
		}
	},
	computed: {
		id () {
			return this.$route.params.id
		}
	}
}
